<template>
	<div>
		<aq-confirmation-modal
			ref="restoreReassessment"
			name="restore-reassessment-dialog"
			title="Restore Reassessment"
			description="You are about to cancel the claim reassessment, any change made will be lost. Are you sure you wish to continue?"
			yes-label="Proceed"
			no-label="Cancel"
			data-qa="claimPage_modal_restoreReassessment"
		/>
		<escalate-claim-modal
			v-if="sharedData.claimId"
			data-qa="claimEnterBasic_modal_escalateClaimModal"
			name="escalate-claim-modal"
			ref="escalateClaimModal"
			:selected-reason="selectedEscalationReason"
			@input="selectedEscalationReason = $event"
		/>
		<aq-modal
			name="claim-changed-modal"
			:click-to-close="false"
		>
			<ClaimChangedModal />
		</aq-modal>
		<aq-modal name="product-details-modal">
			<product-details-modal
				v-if="currentPolicyTerm"
				:policy-term-id="currentPolicyTerm.policyTermId"
			/>
		</aq-modal>
		<div class="pt-5">
			<div class="header">
				<AqCardModeHeader title="Create New Claim" />
				<div
					v-if="pet"
					class="d-flex p-15 section-content"
				>
					<customer-card-info
						data-qa="claimPage_card_customerInfo"
						class="bordered-wrapper breadcrumb-header--bordered flex-1 min-width-0"
						:class="{'special-highlight-card': requireSpecialLicensing(customer.address.regionCounty)}"
						:user="customer"
						:show-buttons="false"
					/>
					<bordered-button-wrapper
						:btn-label="petBtnLabel"
						class="px-1 flex-1 min-width-0"
						content-class="breadcrumb-header--bordered"
						@click.native="onCardClick()"
					>
						<template #content>
							<pet-card-info
								:pet="pet"
								:pets="pets"
								:policy-term="currentPolicyTerm"
								:customer-id="customer.id"
							/>
						</template>
					</bordered-button-wrapper>
					<bordered-button-wrapper
						:btn-label="productBtnLabel"
						content-class="breadcrumb-header"
						class="px-0 flex-1 bordered-product min-width-0"
						:class="{ 'bordered-product--expanded': !isExpandCardMode }"
						@btn-click="onHandleEditProduct"
					>
						<template #content>
							<product-card-info
								:pet="pet"
								@update-policy-term="onUpdatePolicyTerm"
							/>
						</template>
					</bordered-button-wrapper>
				</div>
			</div>
			<aq-steps
				v-if="steps"
				ref="steps"
				:steps="steps"
				:shared-data="sharedData"
				@update-status="onUpdateStatus"
				:brand="brand"
			>
				<template slot="banner">
					<div
						class="col-12 claim-banner d-flex justify-content-between align-items-center py-10 px-44"
						v-if="(sharedData.isReassessment || sharedData.hasPreAuthCopy)"
					>
						<button
							type="button"
							class="btn btn-warning view-versions-btn"
							@click="onShowPreviousVersions(true)"
						>
							View Previous Versions
						</button>
						<reassessment-banner
							v-if="sharedData.isReassessment"
							:claim-id="claimId"
							:allow-restoring="allowRestoring"
							:failed-restore-reason="failedRestoreReason"
							:reassessment-reason="reassesmentReasonDescription"
							:is-read-only-mode="sharedData.isReadOnlyMode"
							@revert-claim="onRevertClaim"
						/>
					</div>
				</template>
			</aq-steps>
			<claim-versions
				data-qa="claimPage_page_claimVersions"
				v-if="showClaimVersions"
				:claim-id="claimId"
				:locale="brand.brandLocale"
				@close="onShowPreviousVersions(false)"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import eventBus from '@commonServices/eventBus';
import ClaimService from '@commonServices/claimService';
import CustomerService from '@commonServices/customerService';
import { claimStepsFabric } from '@commonServices/settings/stepsFabric';
import { claimNumberStatus, readOnlyClaimStates } from '@commonServices/models/ClaimStatusActionEnum';
import { diffInYears, fromStringToDate } from '@commonServices/utils/dateUtils';
import ClaimVersions from '@commonView/ClaimPage/ClaimVersions/ClaimVersions';
import extraService from '@clientCommon/services/extraService';
import ReassessmentBanner from '@clientCommon/components/views/ClaimPage/ReassessmentBanner';
import ClaimChangedModal from '@commonView/ClaimPage/ClaimChangedModal';
import AqCardModeHeader from '@commonWidgets/AqCardModeHeader';
import BorderedButtonWrapper from '@commonView/CustomerPage/PetsLayout/BorderedButtonWrapper';
import { paServiceInstance } from '@commonServices/plugins/policyAdminNavigateService';
import ProductDetailsModal from '@commonView/Shared/ProductDetailsModal';

export default {
	name: 'ClaimPage',
	components: {
		ClaimVersions,
		ReassessmentBanner,
		ClaimChangedModal,
		AqCardModeHeader,
		BorderedButtonWrapper,
		ProductDetailsModal,
	},
	data () {
		return {
			claimId: null,
			allowRestoring: false,
			failedRestoreReason: null,
			pet: null,
			pets: [],
			customer: null,
			steps: null,
			sharedData: {
				hospitalNotificationGenerated: false,
				claimStatus: 0,
				nextStatus: 0,
				isReadOnlyMode: false,
				isFastTrackReviewed: false,
				invoiceNumbers: [],
				fraudCheckReasons: [],
				hasPreAuthCopy: false,
				claimId: 0,
				assignedToUser: null,
			},
			currentPolicyTerm: null,
			showClaimVersions: false,
			selectedEscalationReason: null,
			reassesmentReasonDescription: null,
		};
	},
	created () {
		this.customerId = parseInt(this.$route.params.customerId);
		this.claimId = parseInt(this.$route.params.claimId);
	},
	async beforeMount () {
		eventBus.$on('notify-claim-changed', this.onNotifyClaimChanged);
		await this.getCustomerInfo();
		await this.mountClaimPage();
	},
	beforeDestroy () {
		this.$toasted.clear();
		eventBus.$off('notify-claim-changed', this.onNotifyClaimChanged);
	},
	beforeRouteLeave (to, from, next) {
		const currentStepComponent = this.$refs.steps?.$refs.currentStepComponent;
		if (currentStepComponent.saveDraft && currentStepComponent.isLoaded) {
			currentStepComponent.saveDraft()
				.then(() => next())
				.catch(() => next(false));
		} else {
			next();
		}
	},
	methods: {
		...mapActions(['changeClaimPolicy', 'changeInteractionContext']),
		onHandleEditProduct () {
			paServiceInstance.navigatePolicyPage(this.currentPolicyTerm.policyId);
		},
		onCardClick () {
			this.changeInteractionContext(
				{
					customer: this.customer,
					pet: this.pet,
					claim: null,
				});
		},
		async getCustomerInfo () {
			this.customer = await CustomerService.getCustomerInformation(this.customerId);
			this.pets = this.customer.pets;
		},
		async loadPetClaimData () {
			const data = await ClaimService.getClaim(this.claimId);
			this.sharedData.claim = data;
			this.pet = this.pets.find(pet => pet.id === data.petId);
			this.sharedData.policyId = data.policyId;
			this.changeClaimPolicy(data.policyId);
			this.sharedData.claimId = this.claimId;
			this.sharedData.petId = data.petId;
			this.sharedData.petBreed = this.pet.breedName;
			this.sharedData.petType = this.pet.type;
			this.sharedData.petYearsOld = diffInYears(fromStringToDate(data.createdDateTime), this.pet.birthDate);
			this.sharedData.customerPostalCode = this.customer.address.postalCode;
			this.sharedData.customerCountry = this.customer.address.country;
			this.sharedData.claimStatus = data.status;
			this.sharedData.nextStatus = data.nextStatus; // for rejection pending status we need to know what the next status after approval
			this.sharedData.isReadOnlyMode = this.isClaimReadOnly();
			this.sharedData.isContinuationClaim = data.continuation;
			this.sharedData.isReassessment = data.wasReassessed;
			this.sharedData.hasPreAuthCopy = data.hasPreAuthCopy;
			this.sharedData.hospitalNotificationEnabled = data.hospitalNotificationEnabled;
			this.sharedData.hospitalNotificationGenerated = data.hospitalNotificationGenerated;
			this.sharedData.registeredVets = data.pet.registeredVets?.map(vet => ({ ...vet, isRegisteredVet: !vet.isHistorical })) ?? [];
			this.sharedData.claimVets = data.vets;
			this.sharedData.billingSyncEnabled = data.billingSyncEnabled;
			this.sharedData.isFastTrackReviewed = data.isFastTrackReviewed;
			this.sharedData.multiSubmissionClaimIds = data.multiSubmissionClaimIds;
			this.sharedData.invoiceNumbers = data.invoiceNumbers;
			this.sharedData.assignedToUser = data.claimAssignment?.user;
			this.sharedData.petPolicyTerms = this.pet.policyTerms;
			this.sharedData.fraudCheckReasons = this.sharedData.claimStatus === claimNumberStatus.FraudCheck && this.$can.ViewFraudCheck
				? await extraService.getClaimFraudCheckReasons(this.claimId)
				: [];
			this.allowRestoring = data.allowRestoring && this.$can.ReassessClaim;
			this.failedRestoreReason = data.failedRestoreReason;
			if (data.allowRestoring && !this.$can.ReassessClaim) {
				this.failedRestoreReason = 'No permissions to revert';
			}
			this.steps = claimStepsFabric(this.sharedData.isReadOnlyMode, data.hasClaimItems, data.hasPayments);
			this.reassesmentReasonDescription = data?.reassessmentReason?.description;
		},
		leavePage () {
			if (window.history.length === 1) { // when claim page is opened in new tab leave page will lead to claim pet
				this.$router.push({ name: 'pet', params: { customerId: this.$route.params.customerId, petId: this.pet.id } });
			} else {
				this.$router.back();
			}
		},
		isClaimReadOnly () {
			return readOnlyClaimStates.includes(this.sharedData.claimStatus);
		},
		setInteractionPanelContext () {
			this.changeInteractionContext(
				{
					customer: this.customer,
					pet: this.pet,
					claim: {
						id: this.claimId,
						status: this.sharedData.claimStatus,
						isReadOnly: this.isClaimReadOnly(),
						registeredVets: this.sharedData.claimVets,
						continuation: this.sharedData.isContinuationClaim,
					},
				});
		},
		async mountClaimPage () {
			await this.loadPetClaimData();
			await this.setInteractionPanelContext();
		},
		async onRevertClaim () {
			const userAction = await this.$refs.restoreReassessment.show();
			if (userAction === 'yes') {
				await ClaimService.restoreClaim(this.claimId);
				await this.mountClaimPage();
				if (this.$refs.steps.$refs.currentStepComponent.mountPage) {
					await this.$refs.steps.$refs.currentStepComponent.mountPage();
				}
				return Promise.resolve();
			}

			return Promise.resolve();
		},
		onUpdateStatus (status) {
			this.claimStatus = status;
			this.sharedData.claimStatus = status;
		},
		onShowPreviousVersions (show) {
			this.showClaimVersions = show;

			if (show) {
				this.$refs.steps.$refs.currentStepComponent.updateClaimStepState();
			}
		},
		async onClaimEscalate (haveUnsavedChanges) {
			const userChoise = await this.$refs.escalateClaimModal.show(haveUnsavedChanges);

			const reasonId = userChoise === 'yes' ? this.selectedEscalationReason.id : null;
			this.selectedEscalationReason = null;

			return reasonId;
		},
		onNotifyClaimChanged (payload) {
			if (!this.sharedData.isReadOnlyMode && payload.claimId === this.claimId && payload.userId !== this.currentUser.id) {
				this.$modal.show('claim-changed-modal');
			}
		},
		onUpdatePolicyTerm (policyTerm) {
			this.currentPolicyTerm = policyTerm;
		},
	},
	computed: {
		...mapState(['isOpenDocPanel', 'currentClaimStatus', 'brand', 'currentUser']),
		...mapGetters(['requireSpecialLicensing', 'isExpandCardMode']),
		petBtnLabel () {
			return (this.$can.PolicyAdminUpdatePet && ClaimsConfig.PA_ENABLED && this.isExpandCardMode) && 'Edit Pet';
		},
		productBtnLabel () {
			return (this.$can.PolicyAdminUpdateProduct && ClaimsConfig.PA_ENABLED) && 'Edit Product';
		},
	},
	watch: {
		async isOpenDocPanel (isOpen) {
			if (!isOpen) {
				await this.loadPetClaimData();
			}
		},

		currentClaimStatus (status) {
			if (status) this.onUpdateStatus(status);
		},
	},
	provide () {
		return {
			mountClaimPage: this.mountClaimPage,
			leavePage: this.leavePage,
			onClaimEscalate: this.onClaimEscalate,
		};
	},
};
</script>

<style lang="scss" scoped>
.claim-banner {
  background: var(--bodyBg);

  .view-versions-btn {
    box-shadow: 0 3px 10px var(--listItemsPopupShadow);
  }
}

.header {
  ::v-deep .attributes {
    margin-top: 20px;
  }
}

.section-content {
  gap: 15px;
}

::v-deep .title {
  font-size: 26px;
  line-height: 32px;
  letter-spacing: -0.06em;
  font-weight: bold;
}

.bordered-product {
  z-index: 1;

  &--expanded{
    &:hover {
      min-width: fit-content;
    }
  }
}
</style>
